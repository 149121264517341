<template>
  <Auth>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img :src="require('@/assets/images/logo-login.svg')" alt="" height="40" />
                  </span>
                </router-link>
              </div>
              <p class="text-muted mb-4 mt-3">
                {{ $t("login.tip") }}
              </p>
            </div>

            <form action="#" @submit.prevent="tryToLogIn">
              <b-alert
                variant="danger"
                class="mt-3"
                v-model="isAuthError"
                dismissible
                >{{ authError }}</b-alert
              >
              <div class="form-group mb-3">
                <label for="emailaddress">{{ $t("form.email") }}</label>
                <input
                  class="form-control"
                  v-model="email"
                  type="email"
                  id="emailaddress"
                  :placeholder="$t('form.email-placeholder')"
                  :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div
                  v-if="submitted && $v.email.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.email.required">{{
                    $t("form.email-req")
                  }}</span>
                  <span v-if="!$v.email.email">{{
                    $t("form.email-valid")
                  }}</span>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="password">{{ $t("form.password") }}</label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    id="password"
                    class="form-control"
                    :placeholder="$t('form.password-placeholder')"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />

                  <div
                    class="input-group-append"
                    @click="showPassword = !showPassword"
                    role="button"
                  >
                    <div class="input-group-text">
                      <span v-if="showPassword" class="fe-eye"></span>
                      <span v-else class="fe-eye-off"></span>
                    </div>
                  </div>
                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    {{ $t("form.password-req") }}
                  </div>
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="checkbox-signin"
                    checked
                  />
                  <label class="custom-control-label" for="checkbox-signin">{{
                    $t("form.rememberme")
                  }}</label>
                </div>
              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">
                  {{ $t("form.enter") }}
                </button>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>

<script>
import { mapActions } from "vuex";
import Auth from "../../layouts/auth";
import { required, email } from "vuelidate/lib/validators";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | " + this.$t("form.login"),
    };
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  components: {
    Auth,
  },
  created() {},
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    ...mapActions({
      login: "login",
    }),
    async tryToLogIn() {
      this.submitted = true;
      this.isAuthError = false;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.tryingToLogIn = true;
        this.authError = null;

        let email = this.email;
        let password = this.password;

        try {
          await this.login({ email, password });
          await this.$router.push("/home");

          this.tryingToLogIn = false;
        } catch (error) {
          this.tryingToLogIn = false;
          this.authError = "Incorrect login info";
          this.isAuthError = true;
        }
      }
    },
  },
};
</script>
