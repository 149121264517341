<template>
  <div>
    <div class="account-pages mt-5 mb-5">
      <div class="container">
        <slot />
      </div>
      <!-- end container -->
    </div>
    <!-- end page -->
    <footer class="footer footer-alt">
      2022 &copy; {{ $appConfig.title }}
    </footer>
  </div>
</template>

<script>
/**
 * Auth component
 */
export default {
  data() {
    return {};
  },
};
</script>
